<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex flex-row align-center">
        <v-btn text @click="()=>{this.$router.go(-1)}"><v-icon dark>mdi-arrow-left</v-icon></v-btn>
        <h1>Hire Purchase Config</h1>
        <v-progress-circular
            indeterminate
            color="green"
            v-if="dataLoader"
            style="margin-left: 10px;"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row>
        <v-col>
          <pre>{{ this.data }}</pre>
        </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span class="d-flex flex-row">
          <h3>PPC</h3>
          <v-btn class="ml-2" @click="openPPCDialog" x-small fab color="success"><v-icon>mdi-plus</v-icon></v-btn>
        </span>

        <div style="margin-top: 10px; border: 1px solid green; padding: 20px; border-radius: 7px;" v-for="(item,index) in data.ppc" :key="index">
          <span class="d-flex flex-row">
            <confirmedActionButton
              class="mx-2 mb-3" 
              color="error" 
              xsmall
              fab
              @cb="deletePPC(index)"
              fabIcon="mdi-close"
              :dialogText="'Are you sure you wish to proceed?'"
            />
            <v-btn @click="updatePPC(index)" fab x-small color="info"><v-icon>mdi-content-save</v-icon></v-btn>
          </span>
          <span class="d-flex flex-row">
            <v-text-field class="mr-2" v-model="item.label" outlined dense label="Label"/>
            <v-text-field class="mr-2" v-model="item.description" outlined dense label="Description"/>
            <v-text-field v-model="item.triggerOnDay" number outlined dense label="Trigger on Day"/>
          </span>
          <span class="d-flex flex-row">
            <h3>Actions</h3>
            <v-btn class="ml-2 mb-3" @click="openPPCActionDialog(index)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>            
          </span>
          <div style="margin-top: 10px; border: 1px solid cyan; padding: 20px; border-radius: 7px;" v-for="(action,index2) in item.actions" :key="index2">
            <span class="d-flex flex-row">
              <confirmedActionButton
                class="mx-2 mb-3" 
                color="error" 
                xsmall
                fab
                @cb="deletePPCAction(index,index2)"
                fabIcon="mdi-close"
                :dialogText="'Are you sure you wish to proceed?'"
              />
              <v-btn @click="updatePPCAction" fab x-small color="info"><v-icon>mdi-content-save</v-icon></v-btn>
            </span>
            <span class="d-flex flex-row">
              <v-select
                outlined
                dense
                class="mr-2"
                :items="data.ppcActionNames"
                v-model="action.type"
                label="Type"
              ></v-select>
              <v-text-field class="mr-2" v-model="action.documentTemplate" outlined dense label="Document Template"/>
              <v-text-field class="mr-2" v-model="action.status" number outlined dense label="Status"/>
              <v-text-field class="mr-2" v-model="action.penaltyAmount" number outlined dense label="Penalty Amount"/>
              <v-text-field class="mr-2" v-model="action.penaltyPercent" number outlined dense label="Penalty Percent"/>
              <v-text-field class="mr-2" v-model="action.discountAmount" number outlined dense label="Discount Amount"/>
              <v-text-field v-model="action.discountPercent" number outlined dense label="Discount Percent"/>
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="ppcDialog.isOpen" persistant width="600">
      <v-card>
        <v-card-title>
          Create PPC
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="ppcDialog.data.label" outlined dense label="Label"/>
          <v-text-field v-model="ppcDialog.data.description" outlined dense label="Description"/>
          <v-text-field v-model="ppcDialog.data.triggerOnDay" number outlined dense label="Trigger On Day"/>
          <p>Actions can only be created once the PPC Item has been created.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="this.ppcDialog.isLoading" color="primary" @click="createPPC">Create</v-btn>
          <v-btn color="success" @click="closePPCDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ppcActionDialog.isOpen" persistant width="600">
      <v-card>
        <v-card-title>
          Create PPC Action
        </v-card-title>
        <v-card-text>
          <v-select
            outlined
            :items="data.ppcActionNames"
            v-model="ppcActionDialog.data.type"
            label="Type"
          ></v-select>
          <v-text-field v-model="ppcActionDialog.data.documentTemplate" outlined dense label="Document Template"/>
          <v-text-field v-model="ppcActionDialog.data.status" number outlined dense label="Status"/>
          <v-text-field v-model="ppcActionDialog.data.penaltyAmount" number outlined dense label="Penalty Amount"/>
          <v-text-field v-model="ppcActionDialog.data.penaltyPercent" number outlined dense label="Penalty Percent"/>
          <v-text-field v-model="ppcActionDialog.data.discountAmount" number outlined dense label="Discount Amount"/>
          <v-text-field v-model="ppcActionDialog.data.discountPercent" number outlined dense label="Discount Percent"/>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="this.ppcActionDialog.isLoading" color="primary" @click="createPPCAction(ppcActionDialog.data.index)">Create</v-btn>
          <v-btn color="success" @click="closePPCActionDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapGetters } from 'vuex';
import confirmedActionButton from './../../components/confirmedActionButton.vue';

export default {
  components: {
    confirmedActionButton,
  },
  name: 'Home',
  data () {
    return {
      dataLoader: false,
      formLoader: false,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },
      ppcDialog: {
        isOpen: false,
        isLoading: false,
        data: {}
      },
      ppcActionDialog: {
        isOpen: false,
        isLoading: false,
        data: {}
      },

      isEditable: false,
      isCreate: true,

      singular: "HP",
      singularLower: "HP",
      plural: "HP",
      pluralLower: "HP",

      requiredRules: [
        v => !!v || 'Field is required.'
      ],

      data: [],
      form: {},
      validForm: false,
    }
  },
  computed: {
    ...mapGetters(['getEndpoint'])
  },
  async mounted(){
    try { 
      this.dataLoader = true;

      await this.fetchData();

    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }finally {
      this.dataLoader = false;
    }
  },
  methods: {
    async fetchData(){
      try{
        this.dataLoader = true;

        let res = await axios.get(`${this.getEndpoint}/api/hp/config`)
        if(res.data.error) throw res.data.error
        this.data = res.data.data.value
        console.log("ACN", res.data.ppcActionNames)
        this.data.ppcActionNames = res.data.ppcActionNames
      }
      catch (e) {
        console.error(e)
        this.snack(e, "error");
      }
      finally {
        this.dataLoader = false;
      }
    },
    openPPCDialog(){
      this.ppcDialog.isOpen = true
    },
    closePPCDialog(){
      this.ppcDialog = {
        isOpen: false,
        isLoading: false,
        data: {}
      }
    },
    openPPCActionDialog(index){
      this.ppcActionDialog.data.index = index
      this.ppcActionDialog.isOpen = true
    },
    closePPCActionDialog(){
      this.ppcActionDialog = {
        isOpen: false,
        isLoading: false,
        data: {}
      }
    },
    async createPPC(){
      try{
        this.ppcDialog.isLoading = true;
        this.data.ppc.push(this.ppcDialog.data)

        let res = await axios.put(`${this.getEndpoint}/api/hp/config`, {value: this.data})
        if(res.data.error) throw res.data.error
        await this.fetchData()
      }
      catch (e) {
        console.error(e)
        this.snack(e, "error");
      }
      finally {
        this.closePPCDialog();
      }
    },  
    async updatePPC(){
      try{
        this.ppcDialog.isLoading = true;
        this.dataLoader = true;

        let res = await axios.put(`${this.getEndpoint}/api/hp/config`, {value: this.data})
        if(res.data.error) throw res.data.error
        await this.fetchData()
      }
      catch (e) {
        console.error(e)
        this.snack(e, "error");
      }
      finally {
        this.closePPCDialog();
        this.dataLoader = false;
      }
    }, 
    async deletePPC(index){
      try{
        this.ppcDialog.isLoading = true;
        this.data.ppc.splice(index,1)

        let res = await axios.put(`${this.getEndpoint}/api/hp/config`, {value: this.data})
        if(res.data.error) throw res.data.error
        await this.fetchData()
      }
      catch (e) {
        console.error(e)
        this.snack(e, "error");
      }
      finally {
        this.closePPCDialog();
      }
    },  
    async createPPCAction(index){
      try{
        this.ppcActionDialog.isLoading = true;
        if(!this.data.ppc[index].actions) this.data.ppc[index].actions = []
        this.data.ppc[index].actions.push(this.ppcActionDialog.data)

        let res = await axios.put(`${this.getEndpoint}/api/hp/config`, {value: this.data})
        if(res.data.error) throw res.data.error
        await this.fetchData()
      }
      catch (e) {
        console.error(e)
        this.snack(e, "error");
      }
      finally {
        this.closePPCActionDialog();
      }
    },  
    async updatePPCAction(){
      try{
        this.ppcActionDialog.isLoading = true;
        this.dataLoader = true;

        let res = await axios.put(`${this.getEndpoint}/api/hp/config`, {value: this.data})
        if(res.data.error) throw res.data.error
        await this.fetchData()
      }
      catch (e) {
        console.error(e)
        this.snack(e, "error");
      }
      finally {
        this.closePPCActionDialog();
        this.dataLoader = false;
      }
    }, 
    async deletePPCAction(index,index2){
      try{
        this.ppcActionDialog.isLoading = true;
        this.data.ppc[index].actions.splice(index2,1)

        let res = await axios.put(`${this.getEndpoint}/api/hp/config`, {value: this.data})
        if(res.data.error) throw res.data.error
        await this.fetchData()
      }
      catch (e) {
        console.error(e)
        this.snack(e, "error");
      }
      finally {
        this.closePPCActionDialog();
      }
    },  
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
  }
}
</script>
